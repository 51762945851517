import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { DUDES_PATH } from "../../constants/paths";
import AccountMenu from "./AccountMenu";
import LoginMenu from "./LoginMenu";
import MainMenu from "./MainMenu";

export const Navbar = ({
                         avatarUrl,
                         displayName,
                         authExists,
                         goToAccount,
                         handleLogout,
                         closeAccountMenu,
                         anchorEl,
                         handleMenu,
                         classes
                       }) => (
  <AppBar position="sticky">
    <Toolbar>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.flex}
        component={Link}
        to={authExists ? DUDES_PATH : "/"}>
        Spotek 2.69
      </Typography>
      {authExists ? (
        <React.Fragment>
          <MainMenu/>
          <AccountMenu
            avatarUrl={avatarUrl}
            displayName={displayName}
            onLogoutClick={handleLogout}
            goToAccount={goToAccount}
            closeAccountMenu={closeAccountMenu}
            handleMenu={handleMenu}
            anchorEl={anchorEl}
          />
        </React.Fragment>
      ) : (
        <LoginMenu/>
      )}
    </Toolbar>
  </AppBar>
);

Navbar.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  displayName: PropTypes.string, // from enhancer (flattenProps - profile)
  avatarUrl: PropTypes.string, // from enhancer (flattenProps - profile)
  authExists: PropTypes.bool, // from enhancer (withProps - auth)
  goToAccount: PropTypes.func.isRequired, // from enhancer (withHandlers - router)
  handleLogout: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  closeAccountMenu: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  handleMenu: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  anchorEl: PropTypes.object // from enhancer (withStateHandlers - handleMenu)
};

export default Navbar;
