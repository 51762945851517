import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { reduxFirebase as rrfConfig } from "../config";

export default (store) => {
  // ======================================================
  // Redux + Firebase Config (react-redux-firebase & redux-firestore)
  // ======================================================
  const defaultRRFConfig = {
    userProfile: "users", // root that user profiles are written to
    updateProfileOnLogin: false, // enable/disable updating of profile on login
    presence: "presence", // list currently online users under "presence" path in RTDB
    sessions: null, // Skip storing of sessions
    enableLogging: false // enable/disable Firebase Database Logging
    // profileDecorator: (userData) => ({ email: userData.email }) // customize format of user profile
  };

  // Combine default config with overrides if they exist (set within .firebaserc)
  const combinedConfig = rrfConfig
    ? { ...defaultRRFConfig, ...rrfConfig }
    : defaultRRFConfig;

  return {
    firebase,
    config: combinedConfig,
    dispatch: store.dispatch
  };
}
