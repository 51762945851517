import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CoreLayout from "../layouts/CoreLayout";
import LoginRoute from "./Login";
import SignupRoute from "./Signup";
import ProjectsRoute from "./Projects";
import DudesRoute from "./Dudes";
import IdeasRoute from "./Ideas";
import MeetingsRoute from "./Meetings";
import AccountRoute from "./Account";
import NotFoundRoute from "./NotFound";

export default function createRoutes(store) {
  return (
    <CoreLayout>
      <Switch>
        <Route exact path="/">
          <Redirect to="/dudes"/>
        </Route>
        {/* Build Route components from routeSettings */
          [
            AccountRoute,
            ProjectsRoute,
            SignupRoute,
            LoginRoute,
            DudesRoute,
            IdeasRoute,
            MeetingsRoute
            /* Add More Routes Here */
          ].map((settings, index) => (
            <Route key={`Route-${index}`} {...settings} />
          ))}
        <Route component={NotFoundRoute.component}/>
      </Switch>
    </CoreLayout>
  );
}
