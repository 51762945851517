import { deepOrange, deepPurple } from "@material-ui/core/colors";

export default {
  palette: {
    // type: "dark",
    palette: {
      primary: deepOrange,
      secondary: deepPurple
    }
  },
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    useNextVariants: true
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
};
