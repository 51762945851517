/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = "local";

// Config for firebase
export const firebase = {
  apiKey: "AIzaSyBZKenx0bhZOvsbv0IZ23-_5hSh3ZObWVk",
  authDomain: "spotek-c6325.firebaseapp.com",
  databaseURL: "https://spotek-c6325.firebaseio.com",
  projectId: "spotek-c6325",
  storageBucket: "spotek-c6325.appspot.com",
  messagingSenderId: "541568335146"
};

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  enableLogging: false // enable/disable Firebase Database Logging
};

export default {
  env,
  firebase,
  reduxFirebase
};
