import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import ThemeSettings from "../../theme";
import rrfProps from "../../store/reduxProps";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";


const theme = createMuiTheme(ThemeSettings);

const App = ({ routes, store }) => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps(store)}>
        <Router>{routes}</Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  </MuiThemeProvider>
);

App.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default App;
