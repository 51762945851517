import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { DUDES_PATH, IDEAS_PATH, MEETINGS_PATH } from "../../constants/paths";

const buttonStyle = {
  color: "white",
  textDecoration: "none",
  alignSelf: "center"
};

export const LoginMenu = () => (
  <div>
    <Button style={buttonStyle} component={Link} to={DUDES_PATH}>
      Ziomki
    </Button>
    <Button style={buttonStyle} component={Link} to={IDEAS_PATH}>
      Pomysły
    </Button>
    <Button style={buttonStyle} component={Link} to={MEETINGS_PATH}>
      Spotkania
    </Button>
  </div>
);

export default LoginMenu;
